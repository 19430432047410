<template>
    <v-dialog v-model="dialog" scrollable width="90vw">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small color="blue-grey" class="white--text" style="width: 100%" v-bind="attrs" v-on="on">
                <v-icon left>settings_remote</v-icon>
                Comandos
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary white--text"> {{ UUID }} - {{ IP }} </v-card-title>

            <v-card-text>
                <v-container id="box" style="background-color: white" class="mx-auto rounded-xl white--text" fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col cols="12" lg="4">
                            <h1 class="black--text">Relé 1</h1>
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-btn color="error" style="width: 100%" @click="sendRelay(2, 0)" :disabled="block"> Desabilitar </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-btn color="warning secondary--text" style="width: 100%" @click="sendRelay(2, 1)" :disabled="block"> Habilitar </v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" lg="4">
                            <h1 class="black--text">Relé 2</h1>
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-btn color="error" style="width: 100%" @click="sendRelay(3, 0)" :disabled="block"> Desabilitar </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-btn color="warning secondary--text" style="width: 100%" @click="sendRelay(3, 1)" :disabled="block"> Habilitar </v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" lg="4">
                            <h1 class="black--text">Comandos</h1>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <v-btn color="success" style="width: 100%" @click="testReturn()" :disabled="block"> Teste Modbus GATEWAY </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
        <ConfirmModal ref="confirm" />
    </v-dialog>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";

export default {
    props: {
        UUID: {
            type: String,
            required: true,
        },
        IP: {
            type: String,
            required: true,
        },
    },

    components: {
        ConfirmModal,
    },

    data() {
        return {
            dialog: false,
            block: false,
        };
    },

    methods: {
        sendRelay(relay, command) {
            this.$refs.confirm.open("Confirma?", "Deseja realmente enviar o comando?").then((confirm) => {
                if (confirm) this.sendCommand("api/v2/modbus/holding-register", { ip: this.IP, slaveID: 247, register: 50, value: [relay, command] });
            });
        },
        testReturn() {
            this.sendCommand("api/v2/modbus/holding-register", { ip: this.IP, slaveID: 247, register: 80, value: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] });
        },
        sendCommand(url, parameters) {
            this.block = true;
            this.$http
                .postAlternativeAPI(url, parameters)
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                })
                .finally(() => {
                    this.block = false;
                });
        },
    },
};
</script>